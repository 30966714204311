export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"WARCエージェント（ワークエージェント）| 管理部門（公認会計士・経理など）・バックオフィスの転職・就職なら"},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/img/favicon.ico"}],"style":[],"script":[{"src":"/js/gtag.js","type":"text/javascript"}],"noscript":[{"hid":"gtmBody","innerHTML":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-P2F88N9\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"}],"htmlAttrs":{"lang":"ja","prefix":"og: http://ogp.me/ns#"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"WARCエージェント（ワークエージェント）| 管理部門（公認会計士・経理など）・バックオフィスの転職・就職なら"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'