import { default as _91jobPosition_93yODyWA1OQBMeta } from "/agt/pages/[jobPosition].vue?macro=true";
import { default as _91id_93nMb1elxOtkMeta } from "/agt/pages/articles/[id].vue?macro=true";
import { default as _91id_93E2wJSB8EBGMeta } from "/agt/pages/articles/categories/[id].vue?macro=true";
import { default as indexcXlQetoxORMeta } from "/agt/pages/articles/draft/index.vue?macro=true";
import { default as indexD1CUtKdAWbMeta } from "/agt/pages/articles/index.vue?macro=true";
import { default as indexxBCaFg589tMeta } from "/agt/pages/contact/complete/index.vue?macro=true";
import { default as indexLoXMJ9QMBAMeta } from "/agt/pages/contact/index.vue?macro=true";
import { default as index63Aj93UL7mMeta } from "/agt/pages/feature_articles/[id]/index.vue?macro=true";
import { default as healthcheckkhImo9v9z3Meta } from "/agt/pages/healthcheck.vue?macro=true";
import { default as indexmp6dGhqMjFMeta } from "/agt/pages/index.vue?macro=true";
import { default as indexKFPTN57TiYMeta } from "/agt/pages/lp/001/index.vue?macro=true";
import { default as index8I7AxjC8M9Meta } from "/agt/pages/lp/002/index.vue?macro=true";
import { default as indexW001szIbeOMeta } from "/agt/pages/lp/003/index.vue?macro=true";
import { default as indexvB60r7BqeTMeta } from "/agt/pages/lp/004/index.vue?macro=true";
import { default as indexkubBC0Y4lAMeta } from "/agt/pages/lp/005/index.vue?macro=true";
import { default as indexPHSXQPIQkMMeta } from "/agt/pages/lp/006/index.vue?macro=true";
import { default as indexp7LrMsq531Meta } from "/agt/pages/lp/007/index.vue?macro=true";
import { default as indexpYj28DI0EyMeta } from "/agt/pages/lp/008/index.vue?macro=true";
import { default as _91id_93MJHjHFfYRDMeta } from "/agt/pages/lp/job_positions/[id].vue?macro=true";
import { default as _91id_93T9tt3hAfCpMeta } from "/agt/pages/members/[id].vue?macro=true";
import { default as indexMqPVoPfE5ZMeta } from "/agt/pages/members/index.vue?macro=true";
import { default as indexa9InTCsMEdMeta } from "/agt/pages/registration/index.vue?macro=true";
import { default as indexcp84twCGVNMeta } from "/agt/pages/synca/index.vue?macro=true";
export default [
  {
    name: _91jobPosition_93yODyWA1OQBMeta?.name ?? "jobPosition",
    path: _91jobPosition_93yODyWA1OQBMeta?.path ?? "/:jobPosition()",
    meta: _91jobPosition_93yODyWA1OQBMeta || {},
    alias: _91jobPosition_93yODyWA1OQBMeta?.alias || [],
    redirect: _91jobPosition_93yODyWA1OQBMeta?.redirect || undefined,
    component: () => import("/agt/pages/[jobPosition].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nMb1elxOtkMeta?.name ?? "articles-id",
    path: _91id_93nMb1elxOtkMeta?.path ?? "/articles/:id()",
    meta: _91id_93nMb1elxOtkMeta || {},
    alias: _91id_93nMb1elxOtkMeta?.alias || [],
    redirect: _91id_93nMb1elxOtkMeta?.redirect || undefined,
    component: () => import("/agt/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93E2wJSB8EBGMeta?.name ?? "articles-categories-id",
    path: _91id_93E2wJSB8EBGMeta?.path ?? "/articles/categories/:id()",
    meta: _91id_93E2wJSB8EBGMeta || {},
    alias: _91id_93E2wJSB8EBGMeta?.alias || [],
    redirect: _91id_93E2wJSB8EBGMeta?.redirect || undefined,
    component: () => import("/agt/pages/articles/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcXlQetoxORMeta?.name ?? "articles-draft",
    path: indexcXlQetoxORMeta?.path ?? "/articles/draft",
    meta: indexcXlQetoxORMeta || {},
    alias: indexcXlQetoxORMeta?.alias || [],
    redirect: indexcXlQetoxORMeta?.redirect || undefined,
    component: () => import("/agt/pages/articles/draft/index.vue").then(m => m.default || m)
  },
  {
    name: indexD1CUtKdAWbMeta?.name ?? "articles",
    path: indexD1CUtKdAWbMeta?.path ?? "/articles",
    meta: indexD1CUtKdAWbMeta || {},
    alias: indexD1CUtKdAWbMeta?.alias || [],
    redirect: indexD1CUtKdAWbMeta?.redirect || undefined,
    component: () => import("/agt/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexxBCaFg589tMeta?.name ?? "contact-complete",
    path: indexxBCaFg589tMeta?.path ?? "/contact/complete",
    meta: indexxBCaFg589tMeta || {},
    alias: indexxBCaFg589tMeta?.alias || [],
    redirect: indexxBCaFg589tMeta?.redirect || undefined,
    component: () => import("/agt/pages/contact/complete/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoXMJ9QMBAMeta?.name ?? "contact",
    path: indexLoXMJ9QMBAMeta?.path ?? "/contact",
    meta: indexLoXMJ9QMBAMeta || {},
    alias: indexLoXMJ9QMBAMeta?.alias || [],
    redirect: indexLoXMJ9QMBAMeta?.redirect || undefined,
    component: () => import("/agt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: index63Aj93UL7mMeta?.name ?? "feature_articles-id",
    path: index63Aj93UL7mMeta?.path ?? "/feature_articles/:id()",
    meta: index63Aj93UL7mMeta || {},
    alias: index63Aj93UL7mMeta?.alias || [],
    redirect: index63Aj93UL7mMeta?.redirect || undefined,
    component: () => import("/agt/pages/feature_articles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: healthcheckkhImo9v9z3Meta?.name ?? "healthcheck",
    path: healthcheckkhImo9v9z3Meta?.path ?? "/healthcheck",
    meta: healthcheckkhImo9v9z3Meta || {},
    alias: healthcheckkhImo9v9z3Meta?.alias || [],
    redirect: healthcheckkhImo9v9z3Meta?.redirect || undefined,
    component: () => import("/agt/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: indexmp6dGhqMjFMeta?.name ?? "index",
    path: indexmp6dGhqMjFMeta?.path ?? "/",
    meta: indexmp6dGhqMjFMeta || {},
    alias: indexmp6dGhqMjFMeta?.alias || [],
    redirect: indexmp6dGhqMjFMeta?.redirect || undefined,
    component: () => import("/agt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFPTN57TiYMeta?.name ?? "lp-001",
    path: indexKFPTN57TiYMeta?.path ?? "/lp/001",
    meta: indexKFPTN57TiYMeta || {},
    alias: indexKFPTN57TiYMeta?.alias || [],
    redirect: indexKFPTN57TiYMeta?.redirect || undefined,
    component: () => import("/agt/pages/lp/001/index.vue").then(m => m.default || m)
  },
  {
    name: index8I7AxjC8M9Meta?.name ?? "lp-002",
    path: index8I7AxjC8M9Meta?.path ?? "/lp/002",
    meta: index8I7AxjC8M9Meta || {},
    alias: index8I7AxjC8M9Meta?.alias || [],
    redirect: index8I7AxjC8M9Meta?.redirect || undefined,
    component: () => import("/agt/pages/lp/002/index.vue").then(m => m.default || m)
  },
  {
    name: indexW001szIbeOMeta?.name ?? "lp-003",
    path: indexW001szIbeOMeta?.path ?? "/lp/003",
    meta: indexW001szIbeOMeta || {},
    alias: indexW001szIbeOMeta?.alias || [],
    redirect: indexW001szIbeOMeta?.redirect || undefined,
    component: () => import("/agt/pages/lp/003/index.vue").then(m => m.default || m)
  },
  {
    name: indexvB60r7BqeTMeta?.name ?? "lp-004",
    path: indexvB60r7BqeTMeta?.path ?? "/lp/004",
    meta: indexvB60r7BqeTMeta || {},
    alias: indexvB60r7BqeTMeta?.alias || [],
    redirect: indexvB60r7BqeTMeta?.redirect || undefined,
    component: () => import("/agt/pages/lp/004/index.vue").then(m => m.default || m)
  },
  {
    name: indexkubBC0Y4lAMeta?.name ?? "lp-005",
    path: indexkubBC0Y4lAMeta?.path ?? "/lp/005",
    meta: indexkubBC0Y4lAMeta || {},
    alias: indexkubBC0Y4lAMeta?.alias || [],
    redirect: indexkubBC0Y4lAMeta?.redirect || undefined,
    component: () => import("/agt/pages/lp/005/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHSXQPIQkMMeta?.name ?? "lp-006",
    path: indexPHSXQPIQkMMeta?.path ?? "/lp/006",
    meta: indexPHSXQPIQkMMeta || {},
    alias: indexPHSXQPIQkMMeta?.alias || [],
    redirect: indexPHSXQPIQkMMeta?.redirect || undefined,
    component: () => import("/agt/pages/lp/006/index.vue").then(m => m.default || m)
  },
  {
    name: indexp7LrMsq531Meta?.name ?? "lp-007",
    path: indexp7LrMsq531Meta?.path ?? "/lp/007",
    meta: indexp7LrMsq531Meta || {},
    alias: indexp7LrMsq531Meta?.alias || [],
    redirect: indexp7LrMsq531Meta?.redirect || undefined,
    component: () => import("/agt/pages/lp/007/index.vue").then(m => m.default || m)
  },
  {
    name: indexpYj28DI0EyMeta?.name ?? "lp-008",
    path: indexpYj28DI0EyMeta?.path ?? "/lp/008",
    meta: indexpYj28DI0EyMeta || {},
    alias: indexpYj28DI0EyMeta?.alias || [],
    redirect: indexpYj28DI0EyMeta?.redirect || undefined,
    component: () => import("/agt/pages/lp/008/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MJHjHFfYRDMeta?.name ?? "lp-job_positions-id",
    path: _91id_93MJHjHFfYRDMeta?.path ?? "/lp/job_positions/:id()",
    meta: _91id_93MJHjHFfYRDMeta || {},
    alias: _91id_93MJHjHFfYRDMeta?.alias || [],
    redirect: _91id_93MJHjHFfYRDMeta?.redirect || undefined,
    component: () => import("/agt/pages/lp/job_positions/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93T9tt3hAfCpMeta?.name ?? "members-id",
    path: _91id_93T9tt3hAfCpMeta?.path ?? "/members/:id()",
    meta: _91id_93T9tt3hAfCpMeta || {},
    alias: _91id_93T9tt3hAfCpMeta?.alias || [],
    redirect: _91id_93T9tt3hAfCpMeta?.redirect || undefined,
    component: () => import("/agt/pages/members/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMqPVoPfE5ZMeta?.name ?? "members",
    path: indexMqPVoPfE5ZMeta?.path ?? "/members",
    meta: indexMqPVoPfE5ZMeta || {},
    alias: indexMqPVoPfE5ZMeta?.alias || [],
    redirect: indexMqPVoPfE5ZMeta?.redirect || undefined,
    component: () => import("/agt/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: indexa9InTCsMEdMeta?.name ?? "registration",
    path: indexa9InTCsMEdMeta?.path ?? "/registration",
    meta: indexa9InTCsMEdMeta || {},
    alias: indexa9InTCsMEdMeta?.alias || [],
    redirect: indexa9InTCsMEdMeta?.redirect || undefined,
    component: () => import("/agt/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexcp84twCGVNMeta?.name ?? "synca",
    path: indexcp84twCGVNMeta?.path ?? "/synca",
    meta: indexcp84twCGVNMeta || {},
    alias: indexcp84twCGVNMeta?.alias || [],
    redirect: indexcp84twCGVNMeta?.redirect || undefined,
    component: () => import("/agt/pages/synca/index.vue").then(m => m.default || m)
  }
]