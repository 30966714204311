<template>
  <nuxt-link class="button" @click="props.onClick">
    <span class="button__text">
      {{ text }}
    </span>
  </nuxt-link>
</template>

<script setup lang="ts">
const props = defineProps({
  text: String,
  onClick: {
    type: Function,
    required: true,
  },
});
</script>
