import revive_payload_client_4sVQNw7RlN from "/agt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/agt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/agt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/agt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/agt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/agt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/agt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/agt/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "/agt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/agt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_1UohGbtF8v from "/agt/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import sentry_client_shVUlIjFLk from "/agt/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_1UohGbtF8v,
  sentry_client_shVUlIjFLk
]