<template>
  <NuxtLayout name="article">
    <div class="error">
      <div v-if="props.error?.statusCode === 500">
        <img
          src="/img/error/error_500.svg"
          alt="500エラー画像"
          class="error__img"
        />
        <h2 class="error__headline">ご指定のページが表示できませんでした。</h2>
        <p class="error__description">
          サーバーで問題が発生しているため、ページを表示できません。
          <br v-if="isPc" />
          しばらく時間を置いてからやり直してください。
        </p>
        <Button :text="'WARCエージェント TOPへ'" :on-click="handleError" />
      </div>
      <div v-else>
        <img
          src="/img/error/error_404.svg"
          alt="404エラー画像"
          class="error__img"
        />
        <h2 class="error__headline">ご指定のページが見つかりませんでした。</h2>
        <p class="error__description">
          お探しのページは、移動または削除された可能性があります。
          <br v-if="isPc" />
          下記ボタンより再度アクセスしてください。
        </p>
        <Button :text="'WARCエージェント TOPへ'" :on-click="handleError" />
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  title: "情報が見つかりません",
  meta: [
    {
      name: "description",
      content:
        "WARCエージェントでは「人材紹介の『負』の解消を目指す、新しい転職エージェント」をビジョンに、ハイクラス人材紹介事業を展開しています。",
    },
  ],
});

const props = defineProps({
  error: Object,
});

const isPc = ref(false);
const checkWindowWidth = () => {
  isPc.value = window.innerWidth > 767;
};
const handleError = () => clearError({ redirect: "/" });

onMounted(() => {
  checkWindowWidth();
});
</script>