export default defineNuxtPlugin(async ({ vueApp }) => {
  const sentry = await import('@sentry/vue');
  sentry.init({
    dsn:
      process.env.NODE_ENV === 'development'
        ? ''
        : (useRuntimeConfig().sentryDsn as string),
    app: vueApp,
  });
});
