<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  image: 'https://corp.warc.jp/img/meta/ogp.jpeg',
  url: 'https://agent.warc.jp',
  sameAs: ['https://corp.warc.jp'],
  logo: 'https://corp.warc.jp/img/meta/favicon.ico',
  name: 'WARCエージェント（ワークエージェント）',
  description:
    'WARCエージェント（ワークエージェント）では「人材紹介の『負』の解消を目指す、新しい転職エージェント」をビジョンに、ハイクラス人材紹介事業を展開しています。',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '上大崎2丁目25-2 新目黒東急ビル 9F',
    addressLocality: '品川区',
    addressRegion: '東京都',
    postalCode: '141-0021',
    addressCountry: 'JP',
  },
});
</script>
